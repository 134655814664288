import { EventEmitter, Injectable, Output } from '@angular/core';
import { Course, Union } from '../types/models';
import {Subscription} from "rxjs";
import {CoreService} from "./core.service";
import { LanguageService } from './language.service';

@Injectable({
  providedIn: 'root',
})
export class UnionService {
  @Output() languageChange: EventEmitter<string> = new EventEmitter();
  public union: Union | undefined;
  public courses: Course[] = [];
  public loadingCourseData = true;
  public subscriptions: Subscription[] = [];

  constructor(
    private coreService: CoreService,
    private languageService: LanguageService,
  ) {}


  public loadCourses(): void {
    /* Courses data */
    const params: any = {};
    if (this.languageService.hasCode()) {
      params.language = this.languageService.getCode();
    }
    
    params.union = this.union?.slug;

    /* Mark page as loading */
    this.loadingCourseData = true;
    this.subscriptions.push(
      this.coreService.getCourses(params).subscribe(
        (data) => {
          this.courses = data.records;
          this.loadingCourseData = false;
        },
        () => {
          this.loadingCourseData = false;
        }
      )
    );
  }
}
