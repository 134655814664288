import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LessonComponent } from './lesson/lesson.component';
import { PagesComponent } from './pages.component';
import { CourseComponent } from './course/course.component';
import { FeedbackSurveyComponent } from './feedback-survey/feedback-survey.component';
import { AuthGuard } from '../services/guards/auth.guard';
import { ProfileComponent } from './profile/profile.component';
import { UnitResultsComponent } from './unit-results/unit-results.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { CourseAssessmentAuthComponent } from './course-assessment-auth/course-assessment-auth.component';
import { CertificateComponent } from './certificate/certificate.component';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () =>
      import('./authentication/authentication.module').then(
        (m) => m.AuthenticationModule
      ),
  },
  { path: 'certificates/:slug', component: CertificateComponent },
  {
    path: '',
    component: PagesComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', component: ProfileComponent },
      { path: 'courses/:slug', component: CourseComponent },
      { path: 'lessons/:slug', component: LessonComponent },
      { path: 'surveys/:slug', component: FeedbackSurveyComponent },
      { path: 'responses/:slug', component: UnitResultsComponent },
      { path: 'assessments/auth', component: CourseAssessmentAuthComponent },
      {
        path: '**',
        component: NotFoundComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule {}
