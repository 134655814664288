import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Lesson } from '../../../types/models';
import { UiService } from '../../../services/ui.service';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
})
export class ContentComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() public lesson: Lesson | undefined;
  private observer: any;

  constructor(private uiService: UiService) {}

  ngOnInit(): void {}

  ngOnDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      const threshold = 0.5; // 20%
      this.observer = new IntersectionObserver(
        (entries) => {
          let latestEntry: any = null;
          let latestIndex = 0;
          entries.forEach((entry, index) => {
            if (entry.isIntersecting) {
              if (entry.boundingClientRect.y <= 500) {
                this.uiService.sectionClicked.emit(entry.target.id);
                latestEntry = entry;
                latestIndex = index;
              }
            } else {
              // If it's less than viewport then jump to next entry
              if (entry.boundingClientRect.y <= 100) {
                this.uiService.nextSection.emit(true);
              }
            }
          });
        },
        { threshold }
      );
      const blockList = document.querySelectorAll('.block-text');
      blockList.forEach((block) => {
        this.observer.observe(block);
      });
    }, 0);
  }
}
