<div class="app-footer">
  <div class="container">
    <div class="app-footer__menu noprint">
      <div class="app-footer__menu-item"> <a href="https://policies.google.com/privacy?hl=en-GB">Google Privacy & Terms</a></div>
      <div class="app-footer__menu-item"> <a href="https://www.canopy.education/privacy-policy" target="_blank">Canopy Education CIC Privacy & Terms</a></div>
      <div class="app-footer__menu-item"> Locale
        <select class="app-footer__lang-selector" (change)="onLanguageChange($event)">
          <option *ngFor="let language of languages"
                  [value]="language.code"
                  [selected]="language.code === selectedLanguage?.code">{{ language.code }} {{language.name}}</option>
        </select>
      </div>
    </div>
  </div>
</div>
