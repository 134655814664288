<div class="app-section-header">
  <div class="content-title">
    <div class="unit">{{unit}}</div>
    <div class="group">
      <div class="title">{{title}}</div>
      <a href="{{fileUrl}}" target="_blank" *ngIf="fileUrl" class="url">
        <button mat-button color="primary" class="btn-download">{{"VIEW_IN_DOCS" | translate}}</button>
      </a>
    </div>
  </div>
</div>
