<div class="lesson-complete-container" #completedCard>
  <div class="content-box" *ngIf="lesson">
    <div class="title">
      {{"LESSON_COMPLETE_TITLE" | translate}}
    </div>
    <div class="text" *ngIf="lesson.completion_description">
      {{lesson.completion_description}}
    </div>
    <div class="text" *ngIf="!lesson.completion_description">
      {{"LESSON_COMPLETE_DESCRIPTION" | translate}}
    </div>
    <div class="next-lesson-box" *ngIf="lesson.next_lesson_url" [routerLink]="[lesson.next_lesson_url]">
      <div class="next-lesson-text">{{"NEXT_LESSON" | translate}}</div>
      <mat-icon class="arrow-icon">arrow_forward</mat-icon>
    </div>
  </div>
</div>
