import {
  Component,
  Inject,
  Injector,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Block, Question, QuestionOption } from '../../../types/models';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

export interface DialogData {
  question: Question;
  correctOption: QuestionOption;
}

@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.scss'],
})
export class QuizComponent implements OnInit {
  @ViewChild('correctAnswer', { static: true }) correctAnswer: any;
  @Input() block: Block | undefined;

  constructor(
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit(): void {}

  public onCheckAnswer(question: Question) {
    const correctOption = question.options.find((option) => option.correct);
    const dialogRef = this.dialog.open(this.correctAnswer, {
      width: '600px',
      data: {
        question: question,
        correctOption,
      },
    });
  }

  public onSelect(question: Question, option: QuestionOption) {
    question.selectedOption = option;
  }
}
