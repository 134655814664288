import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable, Subscription } from 'rxjs';
import { SocialLoginPayload, User, UserToken } from '../../types/auth';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private baseUrl: string;
  private subscriptions: Subscription[] = [];

  constructor(private http: HttpClient) {
    this.baseUrl = environment.apiBaseUrl;
  }

  public getToken(): UserToken {
    const user: User = this.getUser();
    if (user) {
      return user.token;
    } else {
      return {};
    }
  }

  public getName(): string {
    const user: User = this.getUser();
    return `${user.first_name} ${user.last_name}`;
  }

  public onGetUserData(params: any): Observable<any> {
    const url = `${this.baseUrl}/${environment.endpoints.api.user}`;
    return this.http.get<User>(url, params);
  }

  public onUpdateUser(payload): Observable<any> {
    const url = `${this.baseUrl}/${environment.endpoints.api.user}`;
    return this.http.patch<User>(url, payload);
  }

  public onDeleteUser(): Observable<any> {
    const url = `${this.baseUrl}/${environment.endpoints.api.user}`;
    return this.http.delete<User>(url);
  }

  public saveJWTToken(data: User): void {
    localStorage.setItem('user', JSON.stringify(data));
  }

  public getUser(): any {
    const user = localStorage.getItem('user');
    if (user) {
      return JSON.parse(user);
    }
  }

  public isAccessTokenExpired(): boolean {
    if (!this.isLoggedIn()) {
      return false;
    }
    const user = this.getUser();
    const expiry = JSON.parse(atob(user.token.access.split('.')[1])).exp;
    return Math.floor(new Date().getTime() / 1000) >= expiry;
  }

  public isRefreshTokenExpired(): boolean {
    if (!this.isLoggedIn()) {
      return false;
    }
    const user = this.getUser();
    const expiry = JSON.parse(atob(user.token.refresh.split('.')[1])).exp;
    return Math.floor(new Date().getTime() / 1000) >= expiry;
  }

  public isLoggedIn(): boolean {
    const user = this.getUser();
    return user && user.token !== undefined && user.token !== null;
  }

  public requestAccessToken(): Observable<User> {
    const url = `${this.baseUrl}/${environment.endpoints.api.refreshToken}`;
    const refreshToken = this.getToken().refresh;
    return this.http.post<User>(url, { refresh: refreshToken });
  }

  public onSocialAuthentication(payload: SocialLoginPayload): Observable<User> {
    return this.http.post<User>(
      `${this.baseUrl}/${environment.endpoints.api.accessToken}`,
      payload
    );
  }

  public onGoogleLogin(params = {}): void {
    this.http
      .get<any>(`${this.baseUrl}/${environment.endpoints.api.googleAuth}`, {
        params,
      })
      .subscribe(
        (data) => {
          window.location.href = data.url;
        },
        (error) => {
          console.log(error);
        }
      );
  }

  public onLogOut(): boolean {
    const user = localStorage.removeItem('user');
    if (user === null || user === undefined) {
      window.location.href = '/';
      return true;
    } else {
      return false;
    }
  }

  private disposeSubscriptions() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
