import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Lesson, Unit } from '../../types/models';

interface UnitLessonTabs {
  type: string;
  lessons: Lesson[];
}

@Component({
  selector: 'app-expansion-panel',
  templateUrl: './expansion-panel.component.html',
  styleUrls: ['./expansion-panel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ExpansionPanelComponent implements OnInit {
  @Input() public units: Unit[] = [];
  public unitTabs = {};
  public panelOpenState = false;

  constructor() {}

  ngOnInit(): void {
    /* Iterate through each unit and create a tab for each one */
    this.units.forEach((unit) => {
      const tabs: UnitLessonTabs[] = [];

      unit.lessons.forEach((lesson) => {
        /* If tab exists then add lessons, if not then create one */
        const tab = tabs.find((tab) => tab.type === lesson.type);
        if (tab) {
          tab.lessons.push(lesson);
        } else {
          tabs.push({ type: lesson.type, lessons: [lesson] });
        }
      });
      this.unitTabs[unit.slug] = tabs;
    });
  }

  public getUrl(lesson): string {
    if (lesson.object !== 'questionnaire') {
      return `/lessons/${lesson.slug}`;
    } else {
      return `/surveys/${lesson.slug}`;
    }
  }
}
