import { Component, OnInit } from '@angular/core';
import { UnionService } from './services/union.service';
import { CoreService } from './services/core.service';
import { Subscription } from 'rxjs';
import { LanguageService } from './services/language.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'google-workspace-training-frontend';
  private subscriptions: Subscription[] = [];

  constructor(
    private unionService: UnionService,
    private coreService: CoreService,
    private languageService: LanguageService
  ) {}

  ngOnInit() {
    const subdomain = window.location.hostname;
    this.coreService.getUnionBySubdomain(subdomain).subscribe((data) => {
      this.unionService.union = data;
     
      this.subscriptions.push(this.languageService.getCodeObservable().subscribe(
        () => this.getCourse()       
      ));
      
      this.getCourse();
    });
  }

  private getCourse(): void {

    /* Courses data */
    const params: any = {};

    params.union = this.unionService.union?.slug;
    if (this.languageService.hasCode()) {
      params.language = this.languageService.getCode();
    }

    if(!params.union || !params.language)
      return;

    this.subscriptions.push(
      this.coreService.getCourses(params).subscribe((data) => {
        this.unionService.courses = data.records;
        this.unionService.loadingCourseData = false;
      })
    );

  }
}
