import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

interface ControlNavigator {
  title: string;
  slug: string;
  label: string;
  buttonText: string;
  url: string;
}

@Component({
  selector: 'app-control-navigator',
  templateUrl: './control-navigator.component.html',
  styleUrls: ['./control-navigator.component.scss'],
})
export class ControlNavigatorComponent implements OnInit {
  @Input() content: ControlNavigator | undefined;

  constructor(private router: Router) {}

  ngOnInit(): void {}

  public navigate(url: string): void {
    this.router.navigateByUrl(url);
  }
}
