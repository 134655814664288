export const endpoints = {
  api: {
    accessToken: 'users/access-token/',
    refreshToken: 'users/token/refresh/',
    googleAuth: 'users/authorization/google/',
    user: 'users/manage/',

    lti: 'v1/lti-auth/{}/',
    ltiAccessToken: 'v1/lti-access-token/',

    unions: 'v1/unions/',
    union: 'v1/unions/{}/',
    unionBySubdomain: 'v1/unions/subdomains/{}/',

    certificate: 'v1/certificates/{}/',

    collections: 'v1/collections/',
    collection: 'v1/collection/{}/',

    courseAssessments: 'v1/course-assessments/',
    courseAssessment: 'v1/course-assessments/{}/',

    courses: 'v1/courses/',
    course: 'v1/courses/{}/',
    courseLanguage: 'v1/courses/{}/languages/{code}/',

    units: 'v1/units/',
    unit: 'v1/units/{}/',

    lessons: 'v1/lessons/',
    lesson: 'v1/lessons/{}/',
    lessonRead: 'v1/lessons/{}/read/',

    tags: 'v1/tags/',
    tag: 'v1/tags/{}/',

    questionnaires: 'v1/questionnaires/',
    questionnaire: 'v1/questionnaires/{}/',

    questions: 'v1/questions/',
    question: 'v1/questions/{}/',

    responses: 'v1/responses/',
    response: 'v1/responses/{}/',

    languages: 'v1/languages/',
    language: "v1/languages/{}"
  },
};
