import { Component, OnInit } from '@angular/core';
import { Course, Union } from '../../types/models';
import { User } from '../../types/auth';
import { CoreService } from '../../services/core.service';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

export interface Certificate {
  union: Union;
  course: Course;
  user: User;
  completion_date: string;
}
@Component({
  selector: 'app-certificate',
  templateUrl: './certificate.component.html',
  styleUrls: ['./certificate.component.scss'],
})
export class CertificateComponent implements OnInit {
  public certificate: Certificate | undefined;
  public loading = true;
  constructor(
    private coreService: CoreService,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    // Retrieve the slug from the URL
    const snapshot = this.activatedRoute.snapshot;
    const slug: string | null = snapshot.paramMap.get('slug');
    if (slug) {
      this.getCertificate(slug);
    }
  }

  public getCertificate(slug: string) {
    this.coreService.getCertificate(slug, {}).subscribe(
      (data) => {
        this.certificate = data;
        this.translate.use(this.certificate.course.language);
        this.loading = false;
      },
      (error) => {
        console.log(error);
        this.loading = false;
      }
    );
  }

  public format(dateString: string): string {
    const date = new Date(Date.parse(dateString));
    const intl = new Intl.DateTimeFormat(this.certificate?.course.language, {
      dateStyle: 'long',
    });
    return intl.format(date);
  }
}
