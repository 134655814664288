<ng-container *ngIf="loading">
  <div class="flex-skeleton desktop-skeleton">
    <div class=" flex flex-1">
      <ngx-skeleton-loader count="1" appearance="line" [theme]="
          {
            height: '250px',
            width: '100%'
          }
      "></ngx-skeleton-loader>
    </div>
    <div class=" flex flex-2">
      <ngx-skeleton-loader count="1" appearance="line" [theme]="
    {
      height: '800px',
                  width: '100%'

    }
"></ngx-skeleton-loader>
    </div>

  </div>
  <div class="flex-skeleton flex-mobile-skeleton">
    <div class="flex">
      <ngx-skeleton-loader count="1" appearance="line" [theme]="
          {
            height: '400px',
            width: '100%'
          }
      "></ngx-skeleton-loader>
    </div>
  </div>
</ng-container>


<ng-container *ngIf="!loading && lesson">
  <div class="course-unit">
    <app-sidebar overviewTitle="{{'OVERVIEW' | translate}}"
                 [sections]="lesson.blocks"
                 [title]="lesson.title"
                 [nextNavigatorControl]="nextControl"
                 [previousNavigatorControl]="prevControl"
                 (scrollTo)="scrollTo($event)"
                 [knowledgeSections]="lesson.knowledge_blocks"></app-sidebar>
    <div class="course-unit__content">
      <app-content [lesson]="lesson"></app-content>
      <ng-container *ngFor="let block of lesson.knowledge_blocks">
        <ng-container *ngIf="block.type === 'question'">
          <app-quiz [block]="block"></app-quiz>
        </ng-container>
        <ng-container *ngIf="block.type === 'challenge'">
          <app-challenge [block]="block"></app-challenge>
        </ng-container>
      </ng-container>
    </div>
  </div>

  <div class="mobile-control">
    <app-control-navigator [content]="nextControl"></app-control-navigator>
    <app-control-navigator [content]="prevControl"></app-control-navigator>
  </div>
  <app-lesson-complete [lesson]="lesson"></app-lesson-complete>

</ng-container>
