<div class="embed-container" id="{{block.slug}}">
  <div class="embed-title-container">
    <div class="embed-icon">
      <img src="assets/img/graduation_cap.svg">
    </div>
    <div class="embed-title">{{block.title | translate}}</div>
  </div>
  <div class="embed-text" *ngIf="sanitizedContent">
    <div [innerHTML]="sanitizedContent"></div>
  </div>
  <img src="{{block.image_url}}" *ngIf="block.lti_assessment?.slug && block.image_url" (click)="launchLTI(block.lti_assessment?.slug)" class="embed-image">
</div>