import { Component, Input, OnInit } from '@angular/core';
import { Block } from '../../../types/models';

@Component({
  selector: 'app-challenge',
  templateUrl: './challenge.component.html',
  styleUrls: ['./challenge.component.scss'],
})
export class ChallengeComponent implements OnInit {
  @Input() block: Block | undefined;
  constructor() {}

  ngOnInit(): void {}
}
