import { Component, OnInit, ViewChild } from '@angular/core';
import { CourseAssessment } from '../../types/models';
import { ActivatedRoute } from '@angular/router';
import { CoreService } from '../../services/core.service';

@Component({
  selector: 'app-course-assessment-auth',
  templateUrl: './course-assessment-auth.component.html',
  styleUrls: ['./course-assessment-auth.component.scss'],
})
export class CourseAssessmentAuthComponent implements OnInit {
  @ViewChild('form', { static: false }) form;
  public assessment: CourseAssessment | undefined;
  public state: string | any;
  public idToken: string | any;
  public nonce: string | any;
  public toolUrl: string | undefined;
  public loading = true;
  public open = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private coreService: CoreService
  ) {}

  ngOnInit(): void {
    // Retrieve the slug from the URL
    const snapshot = this.activatedRoute.snapshot;
    const nonce: string | null = snapshot.queryParamMap.get('nonce');
    const state: string | null = snapshot.queryParamMap.get('state');
    this.nonce = nonce;
    this.state = state;
    this.getLaunchData();
  }

  public getLaunchData() {
    const payload = {
      state: this.state,
      nonce: this.nonce,
    };
    this.coreService.getLTIAccessToken(payload).subscribe((payload) => {
      this.toolUrl = payload.tool_deep_link_endpoint;
      this.idToken = payload.data.id_token;
      setTimeout(() => {
        this.form.nativeElement.submit();
        this.open = true;
      });
    });
  }

  public returnToLesson() {
    window.close();
  }
}
