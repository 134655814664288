<div class="content-wrapper">

  <!--- Profile Header -->
  <div class="container" *ngIf="loadingUserData">
    <ngx-skeleton-loader count="1" appearance="line" [theme]="
    {
      height: '150px'
    }
"></ngx-skeleton-loader>
  </div>

  <div class="container" *ngIf="!loadingUserData && user">
    <div class="txt-introduction-to-google">
      <div class="box-text-introduction">
        <h1 class="profile-component-title">{{"MY_PROFILE" | translate}}</h1>
      </div>
      <div class="profile-component-subtitle">
        {{user.first_name}} {{user.last_name}}
      </div>
    </div>
  </div>

  <!-- Profile Body -->
  <div class="container" *ngIf="unionService.loadingCourseData">
    <ngx-skeleton-loader count="1" appearance="line" [theme]="
    {
      height: '250px',
      marginTop: '30px'
    }
"></ngx-skeleton-loader>
  </div>

  <div class="container" *ngIf="!unionService.loadingCourseData">
    <div class="courses profile-component-courses-list">
      <ng-container *ngIf="unionService.courses.length">
        <mat-card class="course" *ngFor="let course of unionService.courses">
          <img class="image" src="{{course.featured_image_url}}"/>
          <div class="content">
            <div class="subtitle-1 text-center card-title">
              {{course.title}}
            </div>
            <div class="box-progress-lesson flex-bx">
              <div class="box-icon-progress">
                <mat-icon class="donut-icon">donut_large</mat-icon>
                <span class="txt-progress">{{"PROGRESS" | translate}}</span>
                <span class="txt-result">{{course.completion_rate}}%</span>
              </div>
              <div class="box-icon-progress">
                <mat-icon class="format-icon"> format_list_bulleted</mat-icon>
                <span class="txt-progress">{{"UNITS" | translate}}</span>
                <span class="txt-result">{{course.units.length}}</span>
              </div>
              <div class="box-icon-progress">
                <mat-icon class="access-icon">access_time</mat-icon>
                <span class="txt-progress">{{"HOURS" | translate}}</span>
                <span class="txt-result">{{course.estimation}}</span>
              </div>
            </div>
            <div class="actions">
              <div class="profile-component-actions">
                <a class="link-primary" [routerLink]="['/courses/' + course.slug + '/']">
                  {{"VIEW_COURSE" | translate}} <img class="profile-component-arrow" src="../../../assets/img/arrow_forward.svg">
                </a>
              </div>
              <div class="profile-component-actions" *ngIf="course.certificate">
                <a class="link-primary" [routerLink]="['/certificates/' + course.certificate + '/']">
                  {{"VIEW_CERTIFICATE" | translate}} <img class="profile-component-arrow" src="../../../assets/img/arrow_forward.svg">
                </a>
              </div>
            </div>
          </div>
        </mat-card>
      </ng-container>
      <ng-container *ngIf="!unionService.courses.length">
        No courses available
      </ng-container>
    </div>

  </div>

</div>
