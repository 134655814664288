import { Injectable } from '@angular/core';
import { Observable, Subject, take } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  languageCode: string = localStorage.getItem('language') ?? '';
  private languageCodeSubject = new Subject<string>();

  setCode(newCode: string) {
    if(this.languageCode == newCode) return;
    this.languageCode = newCode;
    localStorage.setItem('language', newCode);
    this.languageCodeSubject.next(newCode);
  }

  getCode() {
    return this.languageCode;
  }

  getCodeObservable(): Observable<string> {
    return this.languageCodeSubject.asObservable();
  }

  hasCode() {
    return !!this.languageCode;
  }
}

