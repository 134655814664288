import { Component, OnDestroy, OnInit } from '@angular/core';
import { CoreService } from '../../services/core.service';
import { AuthService } from '../../services/authentication/auth.service';
import { User } from '../../types/auth';
import { Course } from '../../types/models';
import { Title } from '@angular/platform-browser';
import { UnionService } from '../../services/union.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit, OnDestroy {
  public loadingUserData = true;
  public loadingCourseData = false;
  public user: User | undefined;
  public language: string | undefined | null;
  public subscriptions: Subscription[] = [];

  constructor(
    public unionService: UnionService,
    private authService: AuthService,
    private coreService: CoreService,
    private translateService: TranslateService,
    private title: Title
  ) {
    this.title.setTitle('Profile - Google Workspace Training');
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    });
  }

  ngOnInit(): void {
    /* Load user and course data */
    this.loadUserData();

    this.subscriptions.push(
      this.unionService.languageChange.subscribe((language) => {
        this.language = language;
        const params = {
          language: this.language,
          union: this.unionService.union?.slug,
        };
        this.loadCourses(params);
      })
    );
  }

  /* Loads user data from the server */
  public loadUserData(): void {
    this.subscriptions.push(
      this.authService.onGetUserData({}).subscribe(
        (data) => {
          this.user = data;
          setTimeout(() => {
            this.loadingUserData = false;
          }, 0);
        },
        () => {
          this.loadingUserData = false;
        }
      )
    );
  }

  /* Loads course data from the server */
  public loadCourses(params): void {
    /* Mark page as loading */
    this.unionService.loadingCourseData = true;
    this.subscriptions.push(
      this.coreService.getCourses(params).subscribe(
        (data) => {
          this.unionService.courses = data.records;
          this.unionService.loadingCourseData = false;
        },
        () => {
          this.unionService.loadingCourseData = false;
        }
      )
    );
  }
}
