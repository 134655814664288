<mat-accordion *ngFor="let unit of units">
  <mat-expansion-panel class="expansion-panel mat-elevation-z0" (opened)="panelOpenState = true"
                       (closed)="panelOpenState = false">
    <mat-expansion-panel-header>
      <mat-panel-title class="expansion-panel__panel-header">
        <div class="expansion-panel__panel-header-title">
          {{unit.title}}
        </div>
        <div class="expansion-panel__panel-header-subtitle">
          {{unit.description}}
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-tab-group mat-stretch-tabs *ngIf="!unit.lessons.length" animationDuration="0" class="expansion-panel__tabs">
      <mat-tab [label]=" 'DESKTOP' | translate">
        <div class="expansion-panel__tab">
          <div class="expansion-panel__card">
            <div class="expansion-panel__title">
              <div class="expansion-panel__title-text">{{"NO_DESKTOP_LESSON" | translate}}</div>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab [label]="'MOBILE' | translate">
        <div class="expansion-panel__tab">
          <div class="expansion-panel__card">
            <div class="expansion-panel__title">
              <div class="expansion-panel__title-text">{{"NO_MOBILE_LESSON" | translate}}</div>
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
    <mat-tab-group mat-stretch-tabs *ngIf="unit.lessons.length" animationDuration="0" class="expansion-panel__tabs">
      <mat-tab *ngFor="let tab of unitTabs[unit.slug]" [label]="tab.type | uppercase | translate">
        <div class="expansion-panel__tab">
          <a class="expansion-panel__card" *ngFor="let lesson of tab.lessons" [routerLink]="[getUrl(lesson)]">
            <div class="expansion-panel__title">
              <div class="expansion-panel__title-text">{{lesson.title}}</div>
              <div class="expansion-panel__icons" [class.none]="lesson.tags.length === 0" >
                <ng-container *ngFor="let tag of lesson.tags; let i = index;">
                  <img *ngIf="i < 6" class="expansion-panel__icon" [src]="tag.icon_url"/>
                </ng-container>
                <span class="expansion-panel__icon-text"
                      *ngIf="lesson.tags.length >6"> + {{lesson.tags.length - 6}}</span>
              </div>
            </div>
            <div class="group">
              <div class="expansion-panel__time">
                <img src="../../../assets/img/schedule.svg">
                <span class="expansion-panel__time-text">{{lesson.estimation}}</span>
              </div>
              <div class="expansion-panel__status">
                <div class="expansion-panel__status-check">
                  <img *ngIf="lesson.completed" src="../../../assets/img/check-circle.svg">
                  <span class="expansion-panel__status-label" *ngIf="lesson.completed">{{"COMPLETED" | translate}}</span>
                  <span class="expansion-panel__status-label" *ngIf="!lesson.completed">{{"NOT_COMPLETED" | translate}}</span>
                </div>
              </div>
            </div>
            <div class="expansion-panel__redirect">
              <a [routerLink]="[getUrl(lesson)]">
                <img src="../../../assets/img/arrow_forward.svg">
              </a>
            </div>
          </a>
        </div>
      </mat-tab>
    </mat-tab-group>
  </mat-expansion-panel>
</mat-accordion>
