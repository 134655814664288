import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Router } from "@angular/router";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material/material.module';
import { PagesModule } from './pages/pages.module';
import { NopagefoundComponent } from './nopagefound/nopagefound.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthService } from './services/authentication/auth.service';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { CoreService } from './services/core.service';
import * as Sentry from "@sentry/angular-ivy";

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

// export class ApiTranslationLoader implements TranslateLoader {
//   constructor(private http: HttpClient, private route: ActivatedRoute, private url: string) {}

//   public getTranslation(lang?: string): Observable<any> {
//     return this.route.queryParamMap.pipe(
//       map((params) => params.get('language') || lang),
//       switchMap((language) => this.http.get(`${this.url}/${language}`))
//     );
//   }
// }

export class ApiTranslationLoader implements TranslateLoader {
  constructor(private coreService: CoreService) {}

  public getTranslation(lang: string): Observable<any> {
    const translation =  this.coreService.getLanguageI18nModel(lang)
    return translation;
  }
}
  

@NgModule({
  declarations: [AppComponent, NopagefoundComponent],
  imports: [
    BrowserModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        //useFactory: HttpLoaderFactory,
        useFactory: (coreService: CoreService) => new ApiTranslationLoader(coreService),
        deps: [CoreService],
      },
    }),
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MaterialModule,
    PagesModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [
    AuthService, 
    [{ provide: MAT_DIALOG_DATA, useValue: [] }],
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
