<div class="certificate-container">
  <div class="container" *ngIf="loading">
      <ngx-skeleton-loader count="1" appearance="line" [theme]="
      {
        height: '500px',
        marginTop: '30px'
      }
  "></ngx-skeleton-loader>
  </div>
  <div class="container" *ngIf="!loading && certificate">
    <div class="header">
      <div class="logo">
        <img src="/assets/img/google-logo.svg">
      </div>
      <div class="union">
        <img src="{{certificate.union.logo_url}}">
      </div>
    </div>
    <div class="body">
      <h1 class="title">{{"CONGRATULATIONS" | translate}}</h1>
      <div class="subtitle">{{certificate.user.first_name}} {{certificate.user.last_name}}</div>
      <img src="/assets/img/cert.png" class="cert">
      <div class="image-text">{{"CERTIFICATE_COMPLETE" | translate}}</div>
      <div class="course-title">{{certificate.course.title}}</div>
      <p class="date">{{format(certificate.completion_date)}}</p>
    </div>
  </div>

</div>


