<div class="content" *ngIf="lesson">
  <app-section-header [unit]="lesson.unit.title" [title]="lesson.title" [description]="lesson.description"
                      [fileUrl]="lesson.file_url"></app-section-header>

  <ng-container *ngFor="let block of lesson.blocks">
    <ng-container *ngIf="block.type === 'content'">
      <div class="content__text block-text" id="{{block.slug}}">
        <div id="summary" class="content__summary-title subtitle-3">{{block.title}}</div>
        <div class="content__summary-content">
          <img src="{{block.image_url}}" alt="icon" *ngIf="block.image_url"/>
          <div class="content__summary-text">
            <div [innerHTML]="block.content"></div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="block.type === 'embed'">
      <app-embed [block]="block" [lessonSlug]="lesson.slug"></app-embed>
    </ng-container>
  </ng-container>
</div>
