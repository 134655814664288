import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UiService } from '../../services/ui.service';

@Component({
  selector: 'app-knowledge',
  templateUrl: './knowledge.component.html',
  styleUrls: ['./knowledge.component.scss'],
})
export class KnowledgeComponent implements OnInit {
  @Output() scrollTo = new EventEmitter<string>();
  @Input() sections: any[] = [];
  activeSection: any;

  constructor(private uiService: UiService) {}

  ngOnInit(): void {
    this.activeSection = this.sections[0];

    this.uiService.sectionClicked.subscribe((slug) => {
      const index = this.sections.findIndex((s) => s.slug === slug);
      if (index > -1) {
        this.activeSection = this.sections[index];
      }
    });

    this.uiService.nextSection.subscribe(() => {
      const index = this.sections.indexOf(this.activeSection);
      if (index < this.sections.length - 1) {
        this.activeSection = this.sections[index + 1];
      }
    });
  }

  public changeSection(section: any): void {
    this.activeSection = section;
    this.scrollTo.emit(section.slug);
  }
}
