import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PagesComponent } from './pages.component';
import { ComponentsModule } from '../components/components.module';
import { SharedModule } from '../shared/shared.module';
import { AdminReportingComponent } from './admin-reporting/admin-reporting.component';
import { MaterialModule } from '../material/material.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { CourseComponent } from './course/course.component';
import { LessonComponent } from './lesson/lesson.component';
import { ContentComponent } from './lesson/content/content.component';
import { QuizComponent } from './lesson/quiz/quiz.component';
import { MatRadioModule } from '@angular/material/radio';
import { ChallengeComponent } from './lesson/challenge/challenge.component';
import { LessonCompleteComponent } from './lesson/lesson-complete/lesson-complete.component';
import { FeedbackSurveyComponent } from './feedback-survey/feedback-survey.component';
import { PagesRoutingModule } from './pages.routing';
import { ProfileComponent } from './profile/profile.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { UnitResultsComponent } from './unit-results/unit-results.component';
import { MatDialogModule } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { NotFoundComponent } from './not-found/not-found.component';
import { CourseAssessmentComponent } from './course-assessment/course-assessment.component';
import { CourseAssessmentAuthComponent } from './course-assessment-auth/course-assessment-auth.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TranslateModule } from '@ngx-translate/core';
import { CertificateComponent } from './certificate/certificate.component';
import { EmbedComponent } from './lesson/embed/embed.component';

@NgModule({
  declarations: [
    PagesComponent,
    AdminReportingComponent,
    CourseComponent,
    LessonComponent,
    ContentComponent,
    QuizComponent,
    ChallengeComponent,
    LessonCompleteComponent,
    FeedbackSurveyComponent,
    ProfileComponent,
    UnitResultsComponent,
    NotFoundComponent,
    CourseAssessmentComponent,
    CourseAssessmentAuthComponent,
    CertificateComponent,
    EmbedComponent,
  ],
  imports: [
    CommonModule,
    PagesRoutingModule,
    ComponentsModule,
    SharedModule,
    MaterialModule,
    MatDialogModule,
    NgxPaginationModule,
    NgxSkeletonLoaderModule.forRoot({ animation: 'pulse' }),
    RouterModule,
    MatRadioModule,
    MatCheckboxModule,
    TranslateModule,
  ],
  exports: [PagesComponent, AdminReportingComponent],
})
export class PagesModule {}
