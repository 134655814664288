import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from '../../services/authentication/auth.service';
import { UnionService } from '../../services/union.service';
import { TranslateService } from '@ngx-translate/core';
import { CoreService } from 'src/app/services/core.service';
import { LanguageService } from 'src/app/services/language.service';
import { Subscription } from 'rxjs';

interface Language {
  name: string;
  code: string;
  alignment: string;
}

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  @Input() drawer: any;
  public loading = true;
  public languages: Language[] = [];
  public selectedLanguage: Language | null | undefined;
  private languageCodeSubscription: Subscription = Subscription.EMPTY

  constructor(
    public authService: AuthService,
    private unionService: UnionService,
    private translate: TranslateService,
    private coreService: CoreService,
    private languageService: LanguageService
  ) {}

  ngOnInit(): void {
    let code = this.languageService.getCode();

    this.coreService.getLanguages().subscribe((languages) => {
      this.languages = languages.map(e => ({
        code: e.code.toLowerCase(), 
        name: e.title, 
        alignment: e.rtl_alignment ? "rtl" : "ltr"
      }));

      if (code) {
        this.selectedLanguage = this.languages.find(
          (x) => x.code === code
        );
        this.translate.use(code);
      } else {
        this.setDefaultLanguage();
      }
      
      if(this.selectedLanguage){
        this.changeLanguage(this.selectedLanguage.code);
      }

      this.loading = false;
    });

    this.languageCodeSubscription = this.languageService.getCodeObservable().subscribe(
      (newCode) => {
        this.changeLanguage(newCode);
      }
    );
  }

  ngOnDestroy() {
    this.languageCodeSubscription.unsubscribe();
  }

  public setDefaultLanguage(): void {
    /* Set the default language to the union language if it exists */
    if (this.unionService.union) {
      const index = this.languages.findIndex(
        (x) => x.code === this.unionService.union?.language
      );
      if (index > -1) {
        this.selectedLanguage = this.languages[index];
      }
    }
  }

  public onLanguageChange(e): void {
    /* Emit the language change event */
    this.unionService.languageChange.emit(e.target.value);
    this.changeLanguage(e.target.value.toLowerCase());
  }

  public changeLanguage(code: string): void {
    this.languageService.setCode(code);
    this.translate.use(code);
    this.selectedLanguage = this.languages.find(
      (x) => x.code.toLowerCase() === code
    );
    if (this.selectedLanguage?.alignment === 'rtl') {
      document.body.classList.add('rtl');
    } else {
      document.body.classList.remove('rtl');
    }
  }
}
