<form [action]="toolUrl" accept-charset="UTF-8" method="post" target="lti_frame" #form style="display: none">
  <input name="utf8" type="hidden" value="✓">
  <input type="hidden" name="id_token" id="id_token" [value]="idToken">
  <input type="hidden" name="state" id="state" [value]="state">
  <input type="submit" name="commit" value="Perform Launch" class="btn btn-primary" data-disable-with="Perform Launch" #button>
</form>

<iframe name="lti_frame" class="iframe" [class.open]="open" width="100%" height="100%" allowfullscreen>

</iframe>

<div class="button return-button" [class.open]="open" (click)="returnToLesson()">
  Return to lesson
</div>
