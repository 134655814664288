<div class="container-knowledge" *ngIf="sections.length">
  <div class="separation"></div>

  <div class="subtitle-1 overview-text">
    {{"CHECK_YOUR_KNOWLEDGE" | translate}}
  </div>

  <ul class="sections">
    <li *ngFor="let section of sections"  (click)="changeSection(section)">
      <a class="section" [class.active]="section.slug === activeSection.slug">{{section.title}}</a>
    </li>
  </ul>
</div>
