import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CoreService } from '../../services/core.service';
import { Question, Questionnaire, Response, Unit } from '../../types/models';
import { Title } from '@angular/platform-browser';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { DialogData } from '../lesson/quiz/quiz.component';

@Component({
  selector: 'app-unit-results',
  templateUrl: './unit-results.component.html',
  styleUrls: ['./unit-results.component.scss'],
})
export class UnitResultsComponent implements OnInit {
  @ViewChild('answersModal', { static: true }) answersModal: any;
  public loading = true;
  public response: Response | undefined;
  public correctAnswers: number = 0;
  public totalQuestions: number = 0;
  public percentage: number = 0;
  public text = `Thank you for completing the assessment! `;
  public responseTitle: string | undefined;
  private dialogRef: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private coreService: CoreService,
    private title: Title,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit(): void {
    // Retrieve the course slug from the URL
    const snapshot = this.activatedRoute.snapshot;
    const slug: string | null = snapshot.paramMap.get('slug');
    if (slug) {
      this.onLoadResponse(slug);
    }
  }

  public isAnswerSlugArray(question): boolean {
    const response = this.response?.answers.find(
      (x) => x.question_slug === question.slug
    );

    return Array.isArray(response?.answer_slug);
  }

  /* Load response from the backend */
  public onLoadResponse(slug: string): void {
    this.coreService.getResponse(slug).subscribe(
      (res) => {
        this.response = res;

        const markedQuestionMap: {[slug: string]: boolean} = {};
        
        this.response.answers.forEach(e => {
          if(!markedQuestionMap[e.question_slug]){
            markedQuestionMap[e.question_slug] = e.correct;
          }
          else if(!e.correct) {
            markedQuestionMap[e.question_slug] = e.correct;
          }
        })
        this.correctAnswers = Object.keys(markedQuestionMap).filter(e => markedQuestionMap[e]).length;

        this.totalQuestions = this.response.questionnaire.questions.length;
        this.percentage = Math.round(
          (this.correctAnswers / this.totalQuestions) * 100
        );
        this.title.setTitle(
          `Feedback survey results - Google Workspace Training`
        );
        this.loading = false;
      },
      () => {
        this.loading = false;
      }
    );
  }

  /* View response data */
  public onOpenModal() {
    const dialogRef = this.dialog.open(this.answersModal, {
      width: '600px',
    });
  }

  public getUserAnswers(slug){
    const question = this.response?.questionnaire.questions.find(
      (x) => x.slug === slug
    );
    const response = this.response?.answers.find(
      (x) => x.question_slug === slug
    );
    if (question && response) {
       return question.options.filter(e => response.answer_slug.includes(e.slug)).map(e => e.name)
    }
    return;
  }

  /* Return the user's answer */
  public getUserAnswer(slug) {
    const question = this.response?.questionnaire.questions.find(
      (x) => x.slug === slug
    );
    const response = this.response?.answers.find(
      (x) => x.question_slug === slug
    );
    if (question && response) {
      return question.options.find((x) => x.slug === response.answer_slug)
        ?.name;
    }
    return;
  }

  public getCorrectAnswer(slug) {
    const question = this.response?.questionnaire.questions.find(
      (x) => x.slug === slug
    );
    return question?.options.find((x) => x.correct === true)?.name;
  }
  

  public getCorrectAnswers(slug){
    const question = this.response?.questionnaire.questions.find(
      (x) => x.slug === slug
    );
    return question?.options.filter((x) => x.correct === true).map(e => e.name);
  }

  public isAnswerCorrect(question){
    const response = this.response?.answers.find(
      (x) => x.question_slug === question.slug
    );

    return response?.correct;
  }
}
