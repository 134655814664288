import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {Lesson} from '../../../types/models';
import {CoreService} from '../../../services/core.service';
import {ActivatedRoute} from '@angular/router';
import {UnionService} from "../../../services/union.service";

@Component({
  selector: 'app-lesson-complete',
  templateUrl: './lesson-complete.component.html',
  styleUrls: ['./lesson-complete.component.scss'],
})
export class LessonCompleteComponent
  implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('completedCard', {static: false}) completedCard;
  @Input() lesson: Lesson | undefined;
  private observer: IntersectionObserver | undefined;

  constructor(
    private coreService: CoreService,
    private unionService: UnionService,
  ) {
  }

  ngOnDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    const threshold = 0.1; // 20%

    this.observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (this.lesson && !this.lesson.completed) {
              const payload = {
                union: this.unionService.union?.slug,
              };
              this.coreService
                .markLessonAsRead(this.lesson.slug, payload)
                .subscribe((lesson) => {
                  this.lesson = lesson;
                  this.unionService.loadCourses();
                });
            }
          }
        });
      },
      {threshold}
    );
    this.observer.observe(this.completedCard.nativeElement);
  }
}
