import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Block} from '../../../types/models';
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {Subscription} from "rxjs";
import {CoreService} from "../../../services/core.service";

@Component({
  selector: 'app-embed',
  templateUrl: './embed.component.html',
  styleUrls: ['./embed.component.scss'],
})
export class EmbedComponent implements OnInit, OnDestroy {
  @Input() lessonSlug: string = "";
  @Input() block: Block = {
    title: '',
    slug: '',
    content: '',
    object: '',
    type: '',
    created_at: '',
    modified_at: '',
  };
  sanitizedContent!: SafeHtml;
  private subscriptions: Subscription[] = [];
  constructor(
    public sanitizer: DomSanitizer,
    private coreService: CoreService
  ) {}

  ngOnInit() {
    if(this.block.content)
      this.sanitizedContent = this.sanitizeHtml(this.block.content);
  }

  sanitizeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    });
  }

  ngOnChanges() {
    if(this.block.content)
      this.sanitizedContent = this.sanitizeHtml(this.block.content);
  }

  public launchLTI(slug: string | undefined): void {
    localStorage.setItem("lastLessonSlug", this.lessonSlug);
    this.subscriptions.push(
      this.coreService
        .getLTIAuthResponse(slug, {})
        .subscribe((res) => {
          window.open(res.url, '_blank');
        })
    );
  }
}
