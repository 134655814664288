<div class="challenge-container" id="{{block?.slug}}">
  <div class="challenge-title-container">
    <div class="challenge-icon">
      <img src="assets/img/graduation_cap.svg">
    </div>
    <div class="challenge-title">{{"CHALLENGE" | translate}}</div>
  </div>
  <div class="challenge-text">
    {{block?.content}}
  </div>
</div>
