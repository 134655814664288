<div class="sidebar">
  <app-overview [title]="overviewTitle" [sections]="sections" (scrollTo)="scrollToSection($event)"></app-overview>
  <app-knowledge [sections]="knowledgeSections" *ngIf="knowledgeSections" (scrollTo)="scrollToSection($event)"></app-knowledge>
  <div class="control-container">
    <ng-container *ngIf="nextNavigatorControl">
      <app-control-navigator [content]="nextNavigatorControl"></app-control-navigator>
    </ng-container>

    <ng-container *ngIf="previousNavigatorControl">
      <app-control-navigator [content]="previousNavigatorControl"></app-control-navigator>
    </ng-container>
  </div>
</div>
